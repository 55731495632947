module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"incept-website","short_name":"incept","start_url":"/","background_color":"#F9ECEE","theme_color":"#F9ECEE","display":"minimal-ui","icon":"static/incept-favicon.jpg","icons":[{"src":"static/incept-favicon-192.jpg","sizes":"192x192","type":"image/jpg"},{"src":"static/incept-favicon-192.jpg","sizes":"256x256","type":"image/jpg"},{"src":"static/incept-favicon-192.jpg","sizes":"384x384","type":"image/jpg"},{"src":"static/incept-favicon-192.jpg","sizes":"512x512","type":"image/jpg"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"97522f282c498c1024269e285e16bebd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
